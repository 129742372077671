import './Pages.css'
import './ServPage.css'

export default function Thanks() {
  return <>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100vw', height: '100vh'}}>
      <p style={{fontFamily: 'Montserrat', fontSize: '18px'}}>
        Спасибо за заявку!
      </p>
      <a href='/'>
        <button style={{fontFamily: 'Montserrat', fontSize: '18px', borderRadius: '13px', background: '#60CF60', padding: '10px 20px', border: 'none'}}>
          На главную
        </button>
      </a>
    </div>
  </>
}