import Main from "./Components/Main";
import About from "./Components/About";
import Services from "./Components/Services/Services";
import AboutCompany from "./Components/AboutCompany";
import Team from "./Components/Team";
import Feedbacks from "./Components/Feedbacks/Feedbacks";
import Certificates from "./Components/Certificates";
import Contacts from "./Components/Contacts";

import Form from "./Components/Form";
import ServList from "./Components/ServList";

import ServPage from "./Components/Pages/ServPage";
import logo from "./Assets/logo.svg";
import "./Style/App.css";

import React, { useState, useEffect, useRef } from "react";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import {ScrollToTop} from './hooks/scrollToTop'

import Corruption from "./Components/Pages/Corruption";
import Corporate from "./Components/Pages/Corporate";
import Economic from "./Components/Pages/Economic";
import Family from "./Components/Pages/Family";
import Government from "./Components/Pages/Government";
import Housing from "./Components/Pages/Housing";
import Infobusiness from "./Components/Pages/Infobusiness";
import Inheritance from "./Components/Pages/Inheritance";
import Land from "./Components/Pages/Land";
import Mediation from "./Components/Pages/Mediation";
import Property from "./Components/Pages/Property";
import PropertyCrime from "./Components/Pages/PropertyCrime";
import Thanks from "./Components/Pages/Thanks";

function App() {
  useEffect(() => {
    document.title = "Tvoe Pravo";
    
    document.querySelector("meta[name='viewport']").setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.href = logo;
    }
  }, []);

  const [form, setForm] = useState(false);
  const containerRef = useRef(null);
  const updateForm = () => {
    setForm(true);
  };

  const [list, setList] = useState(false);
  const listRef = useRef(null);
  const updateList = () => {
    setList(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setList(false);
    }

    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setForm(false);
    }
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: (<>
        <ScrollToTop/>
        <div className={list ? "list-open" : "list-close"} ref={listRef}>
          <ServList></ServList>
        </div>
        <div className={form || list ? "blur-whole" : ""}>
          <Main updateForm={updateForm} />
          <About />
          <Services
            updateForm={updateForm}
            updateList={updateList}
          ></Services>
          <AboutCompany updateForm={updateForm}></AboutCompany>
          <Team></Team>
          <Feedbacks></Feedbacks>
          <Certificates></Certificates>
          <Contacts></Contacts>
        </div>
      </>)
    },
    {
      path: 'thanks',
      element: <><Thanks/></>
    },
    {
      path: 'services',
      element: <><ScrollToTop/><ServPage updateForm={updateForm}/></>,
    },
    {
      path: 'corporate',
      element: <><ScrollToTop/><Corporate updateForm={updateForm}/></>,
    },
    {
      path: 'corruption',
      element: <><ScrollToTop/><Corruption updateForm={updateForm}/></>,
    },
    {
      path: 'economic',
      element: <><ScrollToTop/><Economic updateForm={updateForm}/></>,
    },
    {
      path: 'family',
      element: <><ScrollToTop/><Family updateForm={updateForm}/></>,
    },
    {
      path: 'government',
      element: <><ScrollToTop/><Government updateForm={updateForm}/></>,
    },
    {
      path: 'housing',
      element: <><ScrollToTop/><Housing updateForm={updateForm}/></>,
    },
    {
      path: 'info-business',
      element: <><ScrollToTop/><Infobusiness updateForm={updateForm}/></>,
    },
    {
      path: 'inheritance',
      element: <><ScrollToTop/><Inheritance updateForm={updateForm}/></>,
    },
    {
      path: 'land',
      element: <><ScrollToTop/><Land updateForm={updateForm}/></>,
    },
    {
      path: 'mediation',
      element: <><ScrollToTop/><Mediation updateForm={updateForm}/></>,
    },
    {
      path: 'property',
      element: <><ScrollToTop/><Property updateForm={updateForm}/></>,
    },
    {
      path: 'property-crime',
      element: <><ScrollToTop/><PropertyCrime updateForm={updateForm}/></>,
    },
    {
      path: 'mediation',
      element: <><ScrollToTop/><PropertyCrime updateForm={updateForm}/></>,
    },
  ])

  return (
    <div className='page-container'>
      <meta name="asd" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      <div className={form ? "form-open" : "form-close"} ref={containerRef}>
        <Form setOpen={setForm}></Form>
      </div>
      <HelmetProvider>
        <RouterProvider router={router}/>
      </HelmetProvider>
    </div>
  );
}

export default App;
